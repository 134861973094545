.homeContainer{
        flex: 5;

        .widgets{
            display: flex;
            padding: 20px;
            gap: 20px;
        }

        .charts{
            height: calc(100vh - (340px));
            width: calc(100% - (40px));
            padding: 20px;
        }

        .table{
            margin: 25px;
            margin-top: 100px;
            width: calc(100% - (50px));
        }
}
