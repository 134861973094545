.single{
    padding: 20px;
}
.top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    p{
        font-size: 24px;
        margin: -20px;
        color: rgba(0, 0, 0, 0.75);
    }
    .Btn{
        padding: 5px 15px;
        font-size: 16px;
        cursor: pointer;
    }
}

.client{
    display: flex;
      
      .userShow {
        flex: 1;
        padding: 20px;
        -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
      }
      
      .userUpdate {
        flex: 1;
        padding: 20px;
        -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
        margin-left: 20px;
      }
      
      .userShowTop {
        display: flex;
        align-items: center;
      }
      
      .userShowTopTitle {
        display: flex;
        flex-direction: column;
      }
      
      .userShowFullname {
        font-weight: 500;
        font-size: 24px;
      }
      
      .userShowBottom{
          margin-top: 10px;
      }
      
      .userShowTitle {
        font-size: 15px;
        font-weight: 500;
        color: rgb(175, 170, 170);
      }
      
      .userShowInfo{
          display: flex;
          align-items: center;
          margin: 20px 0px;
          color: #444;
      }
      
      .userShowIcon{
          font-size: 24px !important;
      }
      
      .userShowInfoTitle{
          margin-left: 10px;
          font-size: 16px;
      }
      
      .userUpdateTitle{
          font-size: 24px;
          font-weight: 500;
      }
      
      .userUpdateForm{
          display: flex;
          margin-top: 20px;
      }
      
      .userUpdateItem{
          display: flex;
          flex-direction: column;
          margin-top: 10px;
      }
      
      .userUpdateItem>label{
          margin-bottom: 5px;
          font-size: 15px;
      }
      
      .userUpdateInput{
          border: none;
          outline: none;
          font-size: 15px;
          width: 350px;
          height: 30px;
          border-bottom: 1px solid gray;
      }
      
      .userUpdateButton{
          border-radius: 5px;
          border: none;
          padding: 5px;
          cursor: pointer;
          background-color: darkblue;
          color: white;
          font-weight: 500;
          font-size: 15px;
          padding: 10px 20px;
          margin: 20px;
      }

      .updated{
        width: 100%;
        background-color: rgba(0, 160, 0, 0.7);
        color: white;
        text-align: center;
        font-weight: 400;
        padding: 5px;
      }
}

.payment{
  margin: 25px;
  width: 60%;
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: #444 2px solid;
  border-radius: 4px;
  .box{
    display: flex;
    flex-direction: column;
    align-items: center;
    input{
      margin-bottom: 10px;
      border: none;
      outline: none;
      border-bottom: 1px solid gray;
      font-size: 16px;
      letter-spacing: 1px;
    }
    button{
      background-color: green;
      color: white;
      padding: 10px 25px;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1px;
      border: none;
      outline: none;
      border-radius: 5px;
      cursor: pointer;
    }
    button:disabled,
    button[disabled]{
      background-color: rgba(0, 128, 0, 0.7);
      cursor: not-allowed;
    }
  }
  .done{
    padding: 5px 50px;
    background-color: cornsilk;
    color: black;
  }
}