.data-table{
    margin: 25px;
    height: 630.5px;
    width: calc(100% - 50px);

    .cellWithStatus {
        padding: 4px 8px;
        border-radius: 5px;

        &.Active{
            color: green;
            background-color: rgba(0, 128, 0, 0.11);
        }
        &.active{
            color: green;
            background-color: rgba(0, 128, 0, 0.11);
        }
        &.Pending {
            color: goldenrod;
            background-color: rgba(189, 189, 3, 0.11);
        }
        &.pending {
            color: goldenrod;
            background-color: rgba(189, 189, 3, 0.11);
        }
        &.Discarded {
            color: red;
            background-color: rgba(255, 0, 0, 0.101);
        }
        &.discarded {
            color: red;
            background-color: rgba(255, 0, 0, 0.101);
        }
    }

    .cellAction{
        display: flex;
        justify-content: space-around;
        width: 100%;

        .viewBtn{
            padding: 2px 6px;
            border-radius: 5px;
            color: darkblue;
            border: 1px solid rgba(0, 0, 139, 0.5);
            cursor: pointer;
        }
        .deleteBtn{
            padding: 2px 6px;
            border-radius: 5px;
            color: crimson;
            border: 1px solid rgba(220, 20, 60, 0.5);
            cursor: pointer;
        }
    }
}