p{
    color: gray;
    padding: 10px;
}

.trans{
    margin: 25px;
    .status {
        padding: 5px;
        border-radius: 5px;
    
        &.paid {
          color: green;
          background-color: rgba(0, 128, 0, 0.151);
        }
        &.created {
          color: goldenrod;
          background-color: rgba(189, 189, 3, 0.103);
        }
        &.cancelled {
          color: red;
          background-color: rgba(255, 0, 0, 0.101);
        }
        &.expired {
          color: red;
          background-color: rgba(255, 0, 0, 0.101);
        }
    }
    .tableCell{
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
    .amt{
      font-size: 16px;
      font-weight: 600;
    }
    .head{
      font-size: 18px;
      font-weight: 600;
    }
}