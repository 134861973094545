.widget{
    display: flex;
    flex-wrap: wrap;
    width: 250px;
    padding: 10px;
    justify-content: space-between;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);
    -moz-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);
    box-shadow: 2px 4px 10px 1px rgba(201,201,201,0.47);
    border-radius: 5px;
    height: 100px;

    .left, .right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title{
            font-weight: 500;
            font-size: 15px;
            color: rgb(161, 159, 159); 
        }
        .counter{
            font-size: 32px;
            font-weight: 300;
            letter-spacing: 1px;
            color: rgb(50, 50, 50);
        }
        .link{
            font-size: 13px;
            text-decoration: underline;
            cursor: pointer;
        }
        .percentage{
            display: flex;
            align-content: center;
            font-size: 15px;

            &.positive{
                color: green;
            }
            &.negative{
                color: red;
            }
        }
        .icon{
            font-size: 22px;
            padding: 4px;
            border-radius: 5px;
            align-self: flex-end;
            color: white;
        }
        .spinner::before {
            animation: 1.5s linear infinite spinner;
            animation-play-state: inherit;
            border: solid 5px #cfd0d1;
            border-bottom-color: #7b22baa0;
            border-radius: 50%;
            content: "";
            height: 30px;
            width: 30px;
            position: absolute;
            transform: translate3d(0, -50%, 0);
            will-change: transform;
        }
        @keyframes spinner {
            0% {
              transform: translate3d(0, -50%, 0) rotate(0deg);
            }
            100% {
              transform: translate3d(0, -50%, 0) rotate(360deg);
            }
        }
    }
}